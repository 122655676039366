section.heading-cta {
    padding: 52px 0;

    @media (max-width: 767px) {
        padding: 24px 0 15px;
    }

    .container {
        text-align: center;

        h2 {
            font-size: 48px;
            line-height: 48px;

            @media (max-width: 767px) {
        		font-size: 28px;
        		line-height: 32px;
        	}
        }

        p {
            margin-bottom: 30px;

            @media (max-width: 767px) {
                margin-bottom: 20px;
            }

            strong {
                color: $color-red;
            }
        }
    }
}

section.full-width-text{
    padding: 52px 0;

    @media (max-width: 767px) {
        padding: 24px 0 15px;
    }

    .container {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $color-red;
        }
    }
}

section.impact-block {
    padding: 40px 0;

    @media (max-width: 767px) {
        padding: 15px 0;
    }

    .container {
        .impact-block {
            width: 33.33333%;
            float: left;
            padding: 30px 25px 45px;
            text-align: left;
            position: relative;

            @media (max-width: 1023px) {
                width: 100%;
            }

            h2 {
                color: #fff;
                margin-bottom: 120px;

                @media (max-width: 767px) {
                    margin-bottom: 50px;
                }
            }

            span.learn-more-container-impact {
                position: absolute;
                bottom: 20px;
                left: 20px;
                width: calc(100% - 40px);
                height: 35px;
                transition: all $transition-out ease-out;

                span.learn-more {
                    padding-left: 0px;
                    font-size: 15px;
                    line-height: 35px;
                    font-family: $font-main;
                    color: #fff;
                }

                .learn-more-arrow {
                    position: relative;
                    top: 2px;
                    left: 10px;
                    font-size: 12px;
                    transition: all $transition-out ease-in-out;
                    color: #fff;
                }
            }

            &:hover {
                span.learn-more-container-impact {
                    background-color: transparent;
                    transition: all $transition-in ease-in-out;

                    .learn-more-arrow {
                        left: 50px;
                    }
                }
            }
        }
    }
}

section.image-text {
    .container {
        @media (max-width: 767px) {
            padding: 0;
        }

        .column {
            width: 50%;
            float: left;

            &.right {
                float: right;
            }

            @media (max-width: 767px) {
                width: 100%;
                float: left;
            }

            &.text-column {
                padding: 70px;

                a.button {
                    margin-top: 20px;
                }

                @media (max-width: 1023px) {
                    padding: 30px;
                }

                @media (max-width: 767px) {
                    padding: 30px 24px;
                }

                &.white {
                    background-color: #fff;
                }

                &.light-grey {
                    background-color: $color-lightGrey;
                }
            }

            &.image-container {
                padding-top: 50%;

                @media (max-width: 767px) {
                    padding-top: 100%;
                }
            }
        }
    }

    &.light-grey {
        background-color: $color-lightGrey;
        margin: 40px 0;

        @media (max-width: 1023px) {
            margin: 0;
        }

        @media (max-width: 767px) {
            margin: 15px 0;
        }
    }

    &.white {
        padding: 40px 0;

        @media (max-width: 1023px) {
            padding: 30px 0;
        }

        @media (max-width: 767px) {
            padding: 15px 0;
        }

        .container {
            .text-column {
                background-color: $color-lightGrey;

                &.white {
                    background-color: #fff;
                }

                &.light-grey {
                    background-color: $color-lightGrey;
                }
            }
        }
    }
}

section.intro-heading {
    padding: 100px 0;

    @media (max-width: 767px) {
        padding: 20px 20px 15px;
    }

    .container {
        .inner-medium {
            h2 {
                color: $color-red;
                line-height: 1.1;
            }
        }
    }
}


section.large-text-events{
    padding: 50px 0;

    @media (max-width: 767px) {
        padding: 20px 20px 15px;
    }

    .container {
        .inner-medium {
            h2 {
                color: $color-red;
                line-height: 1.1;
            }
        }
    }
}

section.heading-text {
    padding: 40px 0 0;

    @media (max-width: 767px) {
        padding: 15px 0;
    }

    .container {
        .inner{
            h2 {
                font-size: 48px;

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 32px;
                    margin-bottom: 10px;
                }
            }

            p {
                line-height: 1.75;
                margin-bottom: 0;

                @media (max-width: 767px) {
                    line-height: 1.57;
                }
            }
        }
    }
}

section.counter-block {
    padding: 40px 0;

    @media (max-width: 767px) {
        padding: 15px 0;
    }

    .container {
        .counter-blocks {
            float: left;
            width: calc(33.333% - 20px);
            margin: 0 15px;
            text-align: center;
            padding: 70px 30px;

            @media (max-width: 1023px) {
                width: calc(33.333% - 16px);
                margin: 0 12px;
            }

            @media (max-width: 767px) {
                width: 100%;
                margin: 0;
                margin-bottom: 8px;
                padding: 34px 30px;
            }

            h2 {
                color: $color-red;
                font-size: 60px;

                @media (max-width: 767px) {
                    font-size: 48px;
                    line-height: 48px;
                }
            }

            .counter-text {
                font-size: 16px;
                line-height: 1.5;
                font-family: $font-semibold;
                letter-spacing: 0.3px;
                text-transform: uppercase;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}


section.counter-block.autofill {
    padding: 40px 0;

    @media (max-width: 767px) {
        padding: 15px 0;
    }

    .container {
        .counter-blocks {
            float: left;
            width: calc(25% - 15px);
            margin: 0 10px;
            text-align: center;
            padding: 70px 30px;

            @media (max-width: 1023px) {
                width: calc(50% - 10px);
                margin: 0 10px 20px;
            }

            @media (max-width: 767px) {
                width: 100%;
                margin: 0;
                margin-bottom: 8px;
                padding: 34px 30px;
            }

            h2 {
                color: $color-red;
                font-size: 60px;

                @media (max-width: 767px) {
                    font-size: 48px;
                    line-height: 48px;
                }
            }

            .counter-text {
                font-size: 16px;
                line-height: 1.5;
                font-family: $font-semibold;
                letter-spacing: 0.3px;
                text-transform: uppercase;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            @media (max-width: 1023px) {
                &:nth-child(2){
                    margin-right: 0;
                }

                &:nth-child(3){
                    margin-left: 0;
                }
            }
        }
    }
}

section.column-block {
    margin: 40px 0;
    padding: 80px 0;

    @media (max-width: 767px) {
        margin: 15px 0;
        padding: 30px 0 40px;

        &.white {
            margin: 0;
        }
    }

    .container {
        h2 {
            float: left;
            width: 50%;

            @media (max-width: 767px) {
                font-size: 22px;
                line-height: 24px;
            }
        }

        a.button {
            float: right;
        }

        .column-container {
            float: left;
            width: 100%;
            padding-top: 20px;

            @media (max-width: 767px) {
                padding-top: 10px;
            }

            &.mobile {
                display: none;
            }

            .column {
                float: left;
                background-color: #fff;
                margin: 0 15px;
                border: 1px solid $color-borderGrey;
                transition: all $transition-out ease-in-out;

                @media (max-width: 767px) {
                    margin: 0;
                }

                .image-container {
                    float: left;
                    width: 100%;
                    padding-top: 66.6666%;
                    position: relative;

                    .flag {
                        background-color: $color-red;
                        text-align: center;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        z-index: 1;
                        width: auto;
                        padding: 0 20px;
                        line-height: 30px;
                        height: 28px;
                        color: #fff;
                        font-size: 16px;
                        font-family: $font-semibold;
                        text-transform: uppercase;
                    }
                }

                .text-container {
                    width: 100%;
                    float: left;
                    padding: 20px 20px 70px;
                    position: relative;

                    .entry-title {
                        text-transform: none;
                        float: left;
                        width: 100%;
                        margin-bottom: 10px;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 28px;
                    }

                    span.eventListingDate {
                        color: #8b011d;
                        float: left;
                        width: 100%;
                        margin-bottom: 10px;
                        font-weight: 500;
                    }

                    p {
                        color: #646464;
                    }

                    span.learn-more-container {
                        position: absolute;
                        bottom: 20px;
                        left: 20px;
                        width: calc(100% - 40px);
                        height: 35px;
                        transition: all $transition-out ease-in-out;

                        span.learn-more {
                            padding-left: 12px;
                            font-size: 15px;
                            line-height: 35px;
                            font-family: $font-main;
                            color: $color-red;
                        }

                        .learn-more-arrow {
                            position: absolute;
                            top: 11px;
                            right: 50%;
                            font-size: 12px;
                            transition: all $transition-out ease-in-out;
                            color: $color-red;
                        }
                    }

                    &:hover {
                        span.learn-more-container {
                            background-color: $color-lightGrey;
                            transition: all $transition-in ease;

                            .learn-more-arrow {
                                left: auto!important;
                                right: 12px!important;
                                // transition: all $transition-in ease!important;
                            }
                        }
                    }
                }

                &:hover {
                    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
                    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
                    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);

                    .entry-title {
                        text-decoration: underline;
                    }
                }
            }

            &.three {
                .column {
                    width: calc(33.333% - 20px);
                }
            }

            &.four {
                .column {
                    width: calc(25% - 22.5px);
                }
            }

            a:first-child span.column {
                margin-left: 0;

                @media (max-width: 1023px) {
                    margin: 0 15px;
                }

                @media (max-width: 767px) {
                    margin: 0;
                }
            }

            a:last-child span.column {
                margin-right: 0;

                @media (max-width: 1023px) {
                    margin: 0 15px;
                }

                @media (max-width: 767px) {
                    margin: 0;
                }
            }

            @media (max-width: 1023px) {
                &.mobile {
                    display: block;
                }

                &.desktop {
                    display: none;
                }
            }

            ul.slick-dots {
                bottom: -50px;

                @media (max-width: 767px) {
                    bottom: -44px;
                }

                li button::before {
                    content: ''!important;
                    width: 10px;
                    height: 10px;
                    background-color: $color-grey;
                    border-radius: 50%;
                    display: block;
                    opacity: 1;
                }

                li button:hover::before,
                li button:focus::before {
                    opacity: 1;
                }

                li.slick-active button::before {
                    opacity: 1;
                    background-color: $color-red;
                }
            }

            &.column-mobile-slider {
                &.three {
                    .column {
                        width: calc(33.333% - 20px);

                        @media (max-width: 1023px) {
                            width: calc(100% - 30px);
                        }

                        @media (max-width: 767px) {
                            width: 100%;
                        }
                    }
                }

                &.four {
                    .column {
                        width: calc(25% - 22.5px);

                        @media (max-width: 1023px) {
                            width: calc(100% - 30px);
                        }

                        @media (max-width: 767px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &.white {
        background-color: #fff;

        @media (max-width: 767px) {
            background-color: $color-lightGrey;
        }
    }
}

section.image-cta {
    height: 480px;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
        height: 310px;
    }

    .container {
        height: 100%;

        .inner-medium {
            height: 100%;

            .v-box {
                width: 100%;
            }

            h2 {
                color: #fff;
                font-size: 60px;
                line-height: 1;
                text-transform: uppercase;
                margin-bottom: 25px;

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 36px;
                    margin-bottom: 12px;
                }
            }

            h4 {
                color: #fff;
                text-transform: none;
                margin-bottom: 24px;

                @media (max-width: 767px) {
            		line-height: 24px;
            	}
            }

            a {
                @media (max-width: 767px) {
                    width: 100%;
                }

                i:before {
                    font-size: 15px;
                    margin-left: 16px;
                    transition: all $transition-out ease-out;
                }

                &:hover {
                    i:before {
                        margin-left: 40px;
                        transition: all $transition-in ease;
                    }
                }
            }

            p.after-button-text {
                margin-top: 15px;
                margin-bottom: 0;
            }
        }
    }
}

section.partners-block {
    padding: 40px 0 80px;

    @media (max-width: 767px) {
        padding: 15px 0 40px;
    }

    &.people-block {
        @media (max-width: 767px) {
            padding: 15px 0 15px;
        }

    }

    .container {
        h2 {
            color: $color-red;
            float: left;
            width: auto;

            @media (max-width: 767px) {
                width: 100%;
                text-align: center;
            }
        }

        .partner-button-container {
            @media (max-width: 767px) {
                width: 100%;
                float: left;
                text-align: center;
            }
        }

        a.button {
            float: right;

            @media (max-width: 767px) {
                float: none;
                display: inline-block;
            }
        }

        .partners {
            margin-bottom: 40px;

            &:first-of-type {
                margin-top: 50px;

                @media (max-width: 767px) {
                    margin-top: 30px;
                }
            }

            h3 {
                text-transform: uppercase;
                margin-bottom: 20px;

                @media (max-width: 767px) {
                    margin-bottom: 10px;
                }
            }

            .partner {
                .logo-container {
                    width: 100%;
                    float: left;
                    padding-top: 66.66667%;
                    background-position: center;
                    background-size: 80%;
                    background-repeat: no-repeat;
                    border: 1px solid $color-lightGrey;
                    margin-bottom: 10px;
                    transition: all $transition-out ease-out;

                    &:hover {
                        border: 1px solid $color-red-hover;
            			transition: all $transition-in ease-in;
                    }
                }

                .partner-container {
                    width: 100%;
                    float: left;

                    .partner-name {
                        font-size: 14px;
                        font-weight: bold;
                        font-family: $font-main;
                        line-height: 1.3;
                        display: block;
                    }

                    .partner-level {
                        font-size: 14px;
                        font-weight: normal;
                        font-family: $font-main;
                        color: $color-darkgrey;
                        line-height: 1.3;
                        display: block;

                        @media (max-width: 767px) {
                            font-size: 12px;
                        }
                    }
                }
            }

            &.brand-partners {
                width: 100%;
                float: left;

                .partner {
                    float: left;
                    width: calc(33.333% - 16px);
                    margin: 0 12px;

                    @media (max-width: 1023px) {
                        width: calc(50% - 12px);
                    }

                    @media (max-width: 640px) {
                        width: 100%;
                        margin: 0;
                        margin-bottom: 16px;
                    }
                }

                .partner-link {
                    &:first-child {
                        .partner {
                            margin-left: 0;
                        }
                    }

                    &:nth-child(3n) {
                        .partner {
                            margin-right: 0;

                            @media (max-width: 1023px) {
                                margin-right: 12px;
                            }
                        }
                    }

                    &:nth-child(2n) {
                        .partner {
                            @media (max-width: 1023px) {
                                margin-right: 0;
                            }
                        }
                    }

                    &:nth-child(3n + 1) {
                        .partner {
                            margin-left: 0;

                            @media (max-width: 1023px) {
                                margin-left: 12px;
                            }
                        }
                    }

                    &:nth-child(2n + 1) {
                        .partner {
                            @media (max-width: 1023px) {
                                margin-left: 0px;
                            }
                        }
                    }
                }
            }

            &.industry-partners {
                .partners-container {
                    align-items: flex-start;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                    width: 100%;
                }

                .partner {
                    &-link {
                        display: block;
                        margin: 0 1% 16px 0;
                        width: 19%;

                        @media (max-width: 1023px) {
                            width: 32%;
                        }

                        @media (max-width: 640px) {
                            width: 49%;
                        }
                    }

                    &-container {
                        float: none;
                    }
                }

                .logo-container {
                    background-size: 80%;
                    display: block;
                    float: none;
                    height: 195px;
                    padding: 0;
                    width: 100%;

                    @media (max-width: 1023px) {
                        background-size: contain;
                    }
                }
            }

            &.foundation-partners {
                width: 100%;
                float: left;

                .partner {
                    float: left;
                    width: calc(25% - 18px);
                    margin: 0 12px;

                    @media (max-width: 1023px) {
                        width: calc(33.333% - 16px);
                    }

                    @media (max-width: 640px) {
                        width: calc(50% - 12px);
                        margin-bottom: 16px;
                    }
                }

                .partner-link {
                    &:first-child {
                        .partner {
                            margin-left: 0;
                        }
                    }

                    &:nth-child(4n) {
                        .partner {
                            margin-right: 0;

                            @media (max-width: 1023px) {
                                margin-right: 12px;
                            }

                            @media (max-width: 640px) {
                                margin-right: 0;
                            }
                        }
                    }

                    &:nth-child(4n + 1) {
                        .partner {
                            margin-left: 0;

                            @media (max-width: 1023px) {
                                margin-left: 12px;
                            }

                            @media (max-width: 640px) {
                                margin-left: 0;
                            }
                        }
                    }

                    &:nth-child(3n) {
                        .partner {
                            @media (max-width: 1023px) {
                                margin-right: 0px;
                            }

                            @media (max-width: 640px) {
                                margin-right: 12px;
                            }
                        }
                    }

                    &:nth-child(4n + 1) {
                        .partner {
                            @media (max-width: 1023px) {
                                margin-left: 0px;
                            }

                            @media (max-width: 640px) {
                                margin-left: 0px;
                            }
                        }
                    }

                    &:nth-child(2n + 1) {
                        .partner {
                            @media (max-width: 640px) {
                                margin-left: 0px;
                            }
                        }
                    }

                    &:nth-child(2n) {
                        .partner {
                            @media (max-width: 640px) {
                                margin-right: 0px;
                            }
                        }
                    }
                }
            }

            &.industry-partners {
                width: 100%;
                float: left;

                .slide-container {
                    width: calc(100% + 24px);
                    float: left;
                    margin: 0 -12px;

                    .slick-slide {
                        float: left;
                        margin: 12px;
                    }

                    ul.slick-dots {
        				bottom: -50px;

        				li button::before {
        					content: ''!important;
        					width: 10px;
        					height: 10px;
        					background-color: $color-grey;
        					border-radius: 50%;
                            display: block;
                            opacity: 1;
        				}

        				li button:hover::before,
        				li button:focus::before {
        					opacity: 1;
        				}

        				li.slick-active button::before {
        					opacity: 1;
                            background-color: $color-red;
        				}
        			}
                }

                &.full-partners {
                    width: 100%;
                    float: left;

                    .partner {
                        float: left;
                        width: calc(16.6667% - 20px);
                        margin: 0 12px;
                        margin-bottom: 25px;

                        @media (max-width: 1023px) {
                            width: calc(25% - 18px);
                            margin-bottom: 20px;
                        }

                        @media (max-width: 640px) {
                            width: calc(50% - 12px);
                            margin-bottom: 20px;
                        }
                    }

                    .partner-link {
                        &:first-child {
                            .partner {
                                margin-left: 0;
                            }
                        }

                        &:nth-child(6n) {
                            .partner {
                                margin-right: 0;
                            }
                        }

                        &:nth-child(6n + 1) {
                            .partner {
                                margin-left: 0;
                            }
                        }

                        @media (max-width: 1023px) {
                            &:nth-child(6n) {
                                .partner {
                                    margin: 0 12px;
                                    margin-bottom: 16px;
                                }
                            }

                            &:nth-child(6n + 1) {
                                .partner {
                                    margin: 0 12px;
                                    margin-bottom: 16px;
                                }
                            }

                            &:nth-child(4n) {
                                .partner {
                                    margin-right: 0;
                                    margin-bottom: 16px;
                                }
                            }

                            &:nth-child(4n + 1) {
                                .partner {
                                    margin-left: 0;
                                    margin-bottom: 16px;
                                }
                            }
                        }

                        @media (max-width: 1023px) {
                            &:nth-child(4n) {
                                .partner {
                                    margin: 0 12px;
                                    margin-bottom: 16px;
                                }
                            }

                            &:nth-child(4n + 1) {
                                .partner {
                                    margin: 0 12px;
                                    margin-bottom: 16px;
                                }
                            }

                            &:nth-child(2n) {
                                .partner {
                                    margin-right: 0;
                                    margin-bottom: 16px;
                                }
                            }

                            &:nth-child(2n + 1) {
                                .partner {
                                    margin-left: 0;
                                    margin-bottom: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.people-block {
        .container {
            .foundation-partners.partners.people {
                margin-top: 20px;

                .partners-container {
                    .partner {
                        margin-bottom: 24px;
                    }

                    .image-container {
                        float: left;
                        width: 100%;
                        padding-top: 66.666%;
                        margin-bottom: 10px;
                        border: 1px solid $color-lightGrey;

                        .image {
                            background-size: cover;
                            background-position: center;
                        }
                    }
                }
            }
        }
    }
}

section.partners-block.partners-listing {
    padding: 30px 0 40px;

    @media (max-width: 767px) {
        padding: 40px 0 40px;
    }
}

section.partners-block.partners-listing.industry-partners-block {
    .container {
        .partners.industry-partners.full-partners {
            width: 100%;
            float: left;

            @media (max-width: 767px) {
                margin-top: 0;
            }

            h3 {
                float: left;
                margin-top: 3px;
            }

            .filter {
                width: auto;
                float: right;

                @media (max-width: 1200px) {
                    width: 100%;
                    float: left;
                }

                @media (max-width: 767px) {
                    margin-top: 20px;
                }

                ul {
                    width: auto;
                    float: left;
                    text-align: left;

                    @media (max-width: 767px) {
                        width: calc(100% + 20px);
                        margin-left: -10px;
                    }

                    li {
                        width: auto;
                        display: inline-block;
                        padding: 0px 15px;
                        margin-bottom: 20px;

                        @media (max-width: 767px) {
                            width: 50%;
                            float: left;
                            margin-bottom: 15px;
                            padding-left: 10px;
                            padding-right: 10px;
                        }

                        a.button {
                            display: block;
                            font-size: 18px;
                            padding: 0px;
                            line-height: 20px!important;
                            height: 20px;
                            border: none;
                            position: relative;
                            color: #646464;

                            @media (max-width: 767px) {
                                display: inline-block;
                                width: auto;
                                font-size: 16px;
                            }

                            &:hover {
                                background-color: transparent;
                                color: #000;
                            }

                            &:after {
                                content: '';
                                background-color: transparent;
                                height: 2px;
                                width: 100%;
                                position: absolute;
                                bottom: -4px;
                                left: 0;
                            }

                            &.is-checked {
                                color: #000;
                                &:after {
                                    background-color: #ed1941;
                                }
                            }
                        }

                        &:first-child {
                            padding-left: 0;

                            @media (max-width: 767px) {
                                padding-left: 10px;
                            }
                        }

                        &:last-child {
                            padding-right: 0;

                            @media (max-width: 767px) {
                                padding-right: 10px;
                            }
                        }
                    }
                }
            }

            .partners-container.grid {
                float: left;
                width: calc(100% + 20px);
                margin-left: -10px;
                margin-top: 10px;

                .partner-link {
                    float: left;
                    width: calc(16.6667% - 20px);
                    margin: 0 10px;
                    margin-bottom: 25px;

                    @media (max-width: 1023px) {
                        width: calc(25% - 20px);
                        margin-bottom: 25px;
                    }

                    @media (max-width: 640px) {
                        width: calc(50% - 20px);
                        margin-bottom: 25px;
                    }

                    .partner {
                        width: 100%;
                        margin: 0;

                        .logo-container {
                            background-size: contain;
                        }
                    }
                }
            }
        }
    }
}

section.generic-form-block {
    padding: 60px 0;

    @media (max-width: 767px) {
        padding: 40px 0 30px;
    }

    .container {
        form {
            margin-top: 20px;
            float: left;
            width: 100%;

            @media (max-width: 767px) {
                margin-top: 10px;
            }
        }

        .inner-medium {
            p {
                font-size: 14px;
            }
        }
    }
}

section.instagram-block {
    padding: 80px 0;

    @media (max-width: 767px) {
        padding: 40px 0;
    }

    .container {

        .instagram-post-container {
            float: left;
            width: 100%;
            padding-top: 25px;

            &.mobile {
                display: none;
            }

            @media (max-width: 767px) {
                &.desktop {
                    display: none;
                }

                &.mobile {
                    display: block;
                }
            }

            .instagram-post {
                width: calc(25% - 6px);
                float: left;
                margin: 4px;
                padding-top: calc(25% - 6px);

                @media (max-width: 1023px) {
                    width: calc(50% - 4px);
                    padding-top: calc(50% - 4px);
                }

                @media (max-width: 767px) {
                    width: 100%;
                    padding-top: 100%;
                    margin: 0;
                }

                i.hover-icon {
                    position: absolute;
                    bottom: 10px;
                    right: 15px;
                    color: #fff;
                    font-size: 20px;
                    opacity: 0;
                    transition: all $transition-out ease-out;
                }

                &:hover {
                    i.hover-icon {
                        opacity: 1;
                        transition: all $transition-in ease;
                    }
                }
            }

            a {
                &:first-child {
                    .instagram-post {
                        margin-left: 0;
                    }
                }

                &:nth-child(4n) {
                    .instagram-post {
                        margin-right: 0;
                    }
                }

                &:nth-child(4n + 1) {
                    .instagram-post {
                        margin-left: 0;
                    }
                }

                @media (max-width: 1023px) {
                    &:nth-child(2n) {
                        .instagram-post {
                            margin-right: 0;
                        }
                    }

                    &:nth-child(2n + 1) {
                        .instagram-post {
                            margin-left: 0;
                        }
                    }
                }
            }

            ul.slick-dots {
                bottom: -50px;

                @media (max-width: 767px) {
                    bottom: -44px;
                }

                li button::before {
                    content: ''!important;
                    width: 10px;
                    height: 10px;
                    background-color: $color-grey;
                    border-radius: 50%;
                    display: block;
                    opacity: 1;
                }

                li button:hover::before,
                li button:focus::before {
                    opacity: 1;
                }

                li.slick-active button::before {
                    opacity: 1;
                    background-color: $color-red;
                }
            }
        }
    }
}

.gm-style .gm-style-iw {}
.gm-svpc,
.gm-style-mtc {
    display: none;
}
span.info-bubble {
    span.site-title {
        width: 100%;
        float: left;
        font-family: $font-semibold;
        text-transform: uppercase;
        font-size: 16px;
        text-align: center;
        color: $color-dark;
        margin-bottom: 6px;
    }
    span.address-one,
    span.address-two {
        width: 100%;
        float: left;
        font-family: $font-base;
        text-align: center;
        color: $color-dark;
        font-size: 12px;
    }
}

section.map-block {
    margin: 0px 0 40px;

    &.margin {
        margin: 40px 0 40px;
    }

    .mobile {
        display: none;
    }

    @media (max-width: 767px) {
        .mobile {
            display: block;
        }
        .desktop {
            display: none;
        }
    }
}

section.heading-cta.feature-block {
    .container {
        .inner-medium {
            max-width: 1060px;

            .image-container {
                float: left;
                width: 100%;
                margin: 20px 0px 50px;
                padding-top: 56%;

                @media (max-width: 1023px) {
                    margin: 10px 0px 30px;
                }
            }
        }
    }

    @media (max-width: 1023px) {
    }
}

.full-width-text iframe {
    min-height: 500px;
    width: 100%;
}