section.heroBanner {
    position: relative;
    height: 640px;
    background-size: cover;
    background-position: center right;
    overflow: hidden;
    margin-top: -40px;

    @media (max-width: 767px) {
        height: 564px;
        overflow: visible;
        margin-bottom: 15px;

        &.active {
            margin-bottom: 203px;
        }
    }

    .container {
        height: 100%;
        position: relative;

        @media (max-width: 767px) {
            padding: 0;
        }

        .text-container {
            float: left;
            max-width: 440px;
            height: calc(100% - 80px);
            text-align: left;

            @media (max-width: 767px) {
                padding: 0 24px;

                .v-wrap {
                    .v-box {
                        vertical-align: bottom;
                    }
                }
            }

            h1 {
                font-size: 52px;
                line-height: 50px;
                color: #fff;

                @media (max-width: 767px) {
                    font-size: 38px;
                    line-height: 38px;
                    margin-bottom: 12px;
                }
            }

            h4 {
                color: #fff;

                @media (max-width: 767px) {
                    margin-bottom: 40px;
                }
            }

            a.learn-more {
                color: #fff;
                text-transform: uppercase;
                font-family: $font-semibold;
                font-size: 20px;
                line-height: 28px;

                i {
                    margin-right: 10px;
                    line-height: 17px;

                    &:before {
                        font-size: 17px;
                    }
                }

                @media (max-width: 767px) {
                    font-size: 18px;

                    i:before {
                        position: relative;
                        top: 1px;
                        font-size: 16px;
                    }
                }
            }
        }

        .cta-container {
            float: left;
            width: 100%;
            height: 80px;
            overflow: hidden;

            @media (min-width: 767px) {
                opacity: 0;
                visibility: hidden;

                &.loaded {
                    opacity: 1;
                    visibility: visible;
                }
            }

            .quick-links-button {
                display: none;
                width: 100%;
                float: left;
                height: 56px;
                line-height: 56px;
                background-color: $color-red;
                color: #fff;
                font-size: 17px;
                font-family: $font-semibold;
                text-transform: uppercase;
                position: relative;
                padding: 0 24px;

                &:after {
                    content:'\0041';
                    font-family: 'mates';
                    position: absolute;
                    font-size: 14px;
                    line-height: 56px;
                    right: 24px;
                    top: -2px;
                    display: block;
                }

                &.active {
                    &:after {
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
            			-moz-transform: rotate(180deg);
            			-o-transform: rotate(180deg);
                    }
                }
            }

            .banner-link {
                width: 25%;
                float: left;
                height: 80px;
                text-align: center;
                line-height: 82px;
                text-transform: uppercase;
                color: #fff;
                font-size: 20px;
                font-family: $font-semibold;
                transition: background-color $transition-out ease-in-out;
                background-color: $color-red;

                @media (max-width: 1023px) {
                    font-size: 17px;
                }

            	&:hover {
            		background-color: $color-red-mid;
            		// transition: all $transition-in ease-in;
            	}
            }

            @media (max-width: 767px) {
                position: absolute;
                bottom: 0;
                height: 56px;
                overflow: hidden;
                transition: all $transition-out ease-out;

                .quick-links-button {
                    display: block;
                }

                .quick-links-container {
                    width: 100%;
                    float: left;
                    position: absolute;
                    top: 56px;
                    display: block;
                    z-index: 1;
                    background-color: $color-red-mid;
                    padding: 10px 0;

                    .banner-link {
                        width: 100%;
                        float: left;
                        background-color: $color-red-mid;
                        text-align: left;
                        padding: 0 24px;
                        line-height: 56px;
                        height: 56px;
                        font-size: 17px;
                        margin: 0;
                    }
                }

                &.active {
                    overflow: visible;
                    transition: all $transition-in ease-in;
                }
            }
        }
    }

    .opacity-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background-image: linear-gradient(to right, rgba(26, 26, 26, 0.66), rgba(0, 0, 0, 0));
        -webkit-filter: blur(14.6px);
        filter: blur(14.6px);

        @media (max-width: 767px) {
            height: 520px;
            width: 100%;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #171717);
        }
    }
}

body.home section.image-text.white {
    padding-top: 0;
}
